import { createApp } from 'vue';
import App from './App.vue';
import { TUIComponents, TUICore, genTestUserSig } from './TUIKit';
// import TUICallKit
import { TUICallKit } from '@tencentcloud/call-uikit-vue';

const SDKAppID = 1600021463; // Your SDKAppID
const secretKey = 'a43a33d6a653d2bf3cf196b57f3312b0436e0b95f0975609bdb54d43dfd5ca7b'; //Your secretKey

const params = new URLSearchParams(window.location.search);
const userID = String(params.get("id"));

// init TUIKit
const TUIKit = TUICore.init({
  SDKAppID,
});
// TUIKit add TUIComponents
TUIKit.use(TUIComponents);
// TUIKit add TUICallKit
TUIKit.use(TUICallKit);

// login TUIKit
TUIKit.login({
  userID: userID,
  userSig: genTestUserSig({
    SDKAppID,
    secretKey,
    userID,
  }).userSig, // The password with which the user logs in to IM. It is the ciphertext generated by encrypting information such as userID.For the detailed generation method, see Generating UserSig
});

createApp(App).use(TUIKit).mount('#app');